import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const AppOverview = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "store/overview.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 324) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={data => (
            <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="App's Dashboard" />
        )}
    />
);
export default AppOverview;
