import React from 'react';
import Img from 'gatsby-image';

const GymTestimonials = ({ data }) => ( 
    <div className="content">
        <div className="testimonials">
            {data.map(i => (
                <div className="item shadow-lg" key={i.name}>
                    <Img fluid={i.image.childImageSharp.fluid} alt={i.name} imgStyle={{ objectFit: 'contain' }} />
                    <div>
                        <h2>{i.name}</h2>
                        <q>{i.text}</q>
                    </div>
                </div>
            ))}
        </div>
        <style jsx>{`
            h1 {
                text-align: center;
            }

            .testimonials {
                margin-top: 40px;
                column-gap: 32px;
            }

            .item {
                background-color: #fff;
                max-width: 500px;
                border-radius: 3px;
                display: inline-block;
                margin: 0 auto 32px;
                width: 100%;

                & > :global(.gatsby-image-wrapper) {
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                }

                & > div {
                    padding: 32px 24px;

                    & h2,
                    & q {
                        text-align: center;
                    }

                    & q {
                        font-size: 16px;
                        line-height: 24px;
                        display: block;
                        font-style: italic;
                        margin: 8px auto 0;
                    }

                    & h2 {
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0;
                        display: block;
                    }
                }
            }

            @media (min-width: 600px) {
                .testimonials {
                    column-count: 2;
                }
            }
        `}</style>
    </div>
);
export default GymTestimonials;
