import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';

const TestimonialSlideshow = ({ slides }) => {
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        let id = setInterval(() => nextTestimonial(), 8000);

        return () => {
            clearInterval(id);
        };
    }, [current]);

    const onTabClick = i => {
        setCurrent(i);
    };

    const nextTestimonial = () => {
        setCurrent(current === slides.length - 1 ? 0 : current + 1);
    };

    const prevTestimonial = () => {
        setCurrent(current === 0 ? slides.length - 1 : current - 1);
    };

    return (
        <div className="testimonial-slideshow">
            <div className="images shadow-lg">
                {slides.map((e, i) => (
                    <div key={i} className={current === i ? 'current' : ''}>
                        <Img fluid={e.image.childImageSharp.fluid} />
                    </div>
                ))}
            </div>
            <div className="tabs shadow-xl">
                <svg
                    onClick={() => prevTestimonial()}
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="m15.41 16.59-4.58-4.59 4.58-4.59-1.41-1.41-6 6 6 6z" />
                </svg>
                {slides.map((testimonial, i) => {
                    return (
                        <span
                            className={current === i ? 'current' : ''}
                            key={i}
                            onClick={() => onTabClick(i)}
                        />
                    );
                })}
                <svg
                    onClick={() => nextTestimonial()}
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="m8.59 16.59 4.58-4.59-4.58-4.59 1.41-1.41 6 6-6 6z" />
                </svg>
            </div>

            <style jsx>{`
                .testimonial-slideshow {
                    position: relative;
                }

                .images {
                    position: relative;
                    overflow: hidden;
                    border-radius: 3px;

                    & > div {
                        width: 100%;
                        opacity: 0;
                        transition: opacity 0.3s;
                        transform: translateZ(0);
                        display: block;

                        &.current {
                            opacity: 1;
                        }

                        & + div {
                            position: absolute !important;
                            top: 0;
                            left: 0;
                        }
                    }
                }

                .tabs {
                    display: flex;
                    padding: 0 8px;
                    margin: 0 auto;
                    background-color: #fff;
                    border-radius: 3px;
                    position: absolute;
                    bottom: -15px;
                    left: 50%;
                    transform: translateX(-50%);

                    & span {
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        margin: 11px 5px;
                        cursor: pointer;
                        border-radius: 50%;
                        background-color: rgba(38, 50, 56, 0.3);
                        transition: background-color 0.3s cubic-bezier(0, 0, 0.2, 1);

                        &.current {
                            background-color: #f44336;
                        }
                    }

                    & svg {
                        margin: 3px 0;
                        cursor: pointer;
                        fill: #263238;
                    }
                }
            `}</style>
        </div>
    );
};

export default TestimonialSlideshow;
