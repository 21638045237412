import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const AppTrainingDiary = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "store/training-diary.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 324) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={data => (
            <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="App's training diary" />
        )}
    />
);
export default AppTrainingDiary;
