import React from 'react';

export default ({ data, children }) => {
    const { small, md, xl } = data.image;
    return (
        <div className="hero">
            <div>
                <div>{children}</div>
            </div>
            <style jsx>{`
            .hero {
                display: flex;
                align-items: flex-end;
                width: 100%;
                height: 690px;
                background-image: url(${small.fluid.src});
                background-size: cover;
                background-position: 75% top;

                & > div {
                    width: 100%;
                    padding: 20px 20px;
                    margin: 0 auto;
                    max-width: 1240px;
                    box-sizing: border-box;
                    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
                }

                & :global(h1),
                & :global(p) {
                    color: #fff;
                }

                & :global(h1) {
                    max-width: 500px;
                    font-weight: 900;
                    font-size: 40px;
                    line-height: 48px;
                    margin: 0 0 24px;
                    text-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                }

                & :global(p) {
                    max-width: 450px;
                    font-size: 18px;
                    margin: 24px 0 16px;
                    opacity: 1;
                    text-shadow: 0 0px 6px rgba(0, 0, 0, 0.38), 0 3px 6px rgba(0, 0, 0, 0.46);
                }

                & :global(a.button) {
                    margin-top: 8px;

                    &:first-of-type {
                        margin-right: 8px;
                    }
                }

                & :global(a.button.white) {
                    background: #fff;
                    color: #263238;

                    &:active {
                        background-color: hsl(200, 0%, 82%);
                        color: hsl(200, 19%, 0%);
                    }
                }
            }

            @media (min-width: 800px) {
                .hero {
                    & > div {
                        padding: 40px 32px;
                    }
                }
            }

            @media (min-width: 900px) {
                .hero {
                    align-items: center;

                    & > div {
                        background: none;
                    }
                }
            }

            @media (min-width: 665px) {
                .hero {
                    background-image: url(${small.fluid.src});
                    background-position: right top;
                }
            }

            @media (min-width: 906px) {
                .hero {
                    background-image: url(${md.fluid.src});
                }
            }

            @media (min-width: 1227px) {
                .hero {
                    background-image: url(${xl.fluid.src});
                }
            }
        `}</style>
        </div>
    )
};
