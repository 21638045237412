import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const AppGymWorkoutPlan = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "store/gym-workout-plan.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 324) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={data => (
            <Img
                fluid={data.placeholderImage.childImageSharp.fluid}
                alt="App's gym workout section"
            />
        )}
    />
);
export default AppGymWorkoutPlan;
